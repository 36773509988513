import { render, staticRenderFns } from "./filesDialog.vue?vue&type=template&id=505bf130&scoped=true&"
import script from "./filesDialog.vue?vue&type=script&lang=ts&"
export * from "./filesDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505bf130",
  null
  
)

export default component.exports